
import React, {useEffect} from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from ".";
import FeedBroad from './feed/feedBroad';
import UserProfile from "./app/userProfile/user-profile";
import CreatePost from "./postList/createPost";
import UserProfileEdit from "./app/userProfile/user-profile-edit";
import SearchRecommend from "./search/searchRecom";
import SearchPage from "./search/searchPage";
import PostComment from './postList/postComment';
import CreaetProfile from './loginPage/createProfile';
import { UserNameProvider } from './userNameContext';
import TermOfService from './login/termOfService/termOfService';
import UserTerm from './app/userProfile/userProfileInfo/userTerm';
import Chat from './service/chat/createProfile';
import ServicePage from './service/servicePage';


function RouteIndex() {
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    const userId = localStorage.getItem('userId');
    const userName = localStorage.getItem('userName');
    


    if (currentPath === '/' && userId) {      
      navigate('/feed/all')
    } else if(currentPath === '/' && !userId) {
      navigate('/login');
    }


    
    if (!userName) {      
      navigate('/login');
    } 


     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]); 

  const NotFoundRoute = () => {
    useEffect(() => {
      navigate('/feed/all');
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
  };

  return (
      <UserNameProvider>
        <Routes>
          <Route path="/login" element={<Index />} />
          <Route path="/pdpa" element={<TermOfService />} />
          <Route path="/selectProfile" element={<CreaetProfile />} />
          <Route path="/feed/:categories" element={<FeedBroad />} />
          <Route path="/feed/search" element={<SearchRecommend />} />
          <Route path="/feed/search/:searchType/:search" element={<SearchPage />} />
          <Route path="/comment/:postId" element={<PostComment />} />
          <Route path="/dashboard/app/profilelist/:userId" element={<UserProfile />} />
          <Route path="/dashboard/app/bookmarklist/:userId" element={<UserProfile />} />
          <Route path="/dashboard/app/profilelist/edit/:postId" element={<UserProfileEdit />} />
          <Route path="/dashboard/app/profile/edit" element={<UserProfileEdit />} />
          <Route path="/userTerm" element={<UserTerm />} />
          <Route path="/createpost" element={<CreatePost />} />
          <Route path="/edit/post/:postId" element={<CreatePost />} />
          <Route path="/Chat" element={<Chat />} />
          <Route path="/service" element={<ServicePage />} />

        
          {/* Wildcard route for any unmatched path */}
          <Route path="*" element={<NotFoundRoute />} />
        </Routes>
      </UserNameProvider>
  );
}

export default RouteIndex;
