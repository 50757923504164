import React , {useState, useEffect} from 'react';
import { Container} from 'react-bootstrap'
import './otpPage.scss'
import LoginHeader from './loginHeader';
import OtpInput from "react-otp-input";
import loginApi from '../../../service/serviceLogin';
import Swal from 'sweetalert2';
import Loading from '../helper/loading';

const OtpPage = (props) => {
    const userNameLogin = localStorage.getItem("userName")
    const loginRef = props.loginRef


    const [newRef, setNewRef] = useState('');
    const [otp, setOtp] = useState('');
    const [incorrectOtp, setIncorrectOtp] = useState(false)
    const loginOTP = new loginApi.OTP()
    const processLogin = new loginApi.ProcessLogin()
    const [time, setTime] = useState(300); // 5 minutes in seconds
    const [formattedTime, setFormattedTime] = useState('05:00');
    const [loading, setLoading] = useState(false)
    
    
    const handleOtpChange = (inputOtp) => {
        setOtp(inputOtp);
        if (inputOtp.length === 6) {          
            checkOtp(inputOtp);
        } else {
            setIncorrectOtp(false)
        }
    };

    const checkOtp = (otp) => {
        setLoading(true)
        const data = {
            "msisdn": userNameLogin,
            "reference": newRef ? newRef : loginRef,
            "otp": otp,
        }
        loginOTP.verifyOTP(data).then((res)=> {
            if(res.data.status) {                
                localStorage.removeItem("refreshToken");
                localStorage.setItem("refreshToken", res.data.refresh_token);
                processLogin.checkProcessLogin(userNameLogin).then((res)=>{
                    if(res.data.pincode) {
                        props.handleStateLoginChange("pinConfirm")
                    } else {
                        props.handleStateLoginChange("pinCreate")
                    }
                })                               
            } else {
                setLoading(false)
                setIncorrectOtp(true)
            }
        })
    };

    useEffect(() => {
        const timer = setInterval(() => {
          setTime(prevTime => prevTime - 1);
        }, 1000);
    
        // Update formatted time
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        setFormattedTime(
          `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        );
    
        // Clear interval when time reaches 0
        if (time === 0) {
          clearInterval(timer);
        }
    
        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, [time]);
    

    const handleGetOtpAgain = async () => {
        try {
            const result = await Swal.fire({
              icon: 'question',
              iconColor: '#1567B4',
              imageWidth: 100,
              title: "ยืนยันที่จะขอรหัส OTP ใหม่",
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'ยืนยัน',
              cancelButtonText: 'ยกเลิก',
              reverseButtons: true,
              confirmButtonColor: '#1567B4',
            });
        
            if (result.isConfirmed) {
                getOtpAgain()
            }
          } catch (error) {
            console.error(error);
          }
    }

    const getOtpAgain = async () => {
        setFormattedTime('05:00')
        setTime(300)
        let data = {
            "msisdn": userNameLogin,
        }
        try {
            const res = await loginOTP.getOTP(data)
            setNewRef(res.data.reference)
            
        } catch (error) {
            console.log(error)
        }
    }

    const checkTypeUsername = () => {
        if(userNameLogin.includes("@")) {
            return `ไปยังอีเมลล์  ${userNameLogin}`
        } else {
            return `ไปยังเบอร์โทร  ${userNameLogin}`
        }
    }

    return (
        <div id="otpPage">
            <LoginHeader 
                handleStateLoginChange={()=>props.handleStateLoginChange("login")}
                headerText="ยืนยันรหัส OTP"
            />
            <Container className='d-flex flex-column align-items-center pt-5'>
                    <img 
                        className='mb-2'
                        src={process.env.PUBLIC_URL+'/images/login/otpLogo.png'}
                        alt="otpLogo"
                    />
                    <div className='d-flex flex-row justify-content-between mb-4'>
                        <img 
                            className='clockLogo me-2'
                            src={process.env.PUBLIC_URL+'/images/login/clockLogo.png'}
                            alt="clockLogo"
                        />
                        <p className='countDownOtp'>{formattedTime}</p>
                    </div>
                    <div className='inputContainer d-flex flex-column align-items-center'>
                        <p className='text-center'>ส่งรหัส OTP {loginRef && checkTypeUsername()} ของคุณที่ลงทะเบียน</p>
                        {
                            loading ?
                            <Loading />
                                :
                            <>                                                
                            <OtpInput
                                value={otp}
                                onChange={handleOtpChange}
                                numInputs={6}
                                inputType="number"
                                renderSeparator={<span style={{ width: "8px" }}></span>}
                                renderInput={(props, index) => (
                                    <input
                                        type="number"
                                        {...props}
                                        value={otp[index] || ""} 
                                            style={{
                                                border: `2px solid  ${incorrectOtp ? "red":
                                                otp?.length === index ? "white" : "transparent"
                                            }`,
                                            borderRadius: "8px",
                                            width: "50px",
                                            height: "50px",
                                            fontSize: "12px",
                                            color: "#000",
                                            textAlign: "center",
                                            fontWeight: "400",
                                            caretColor: "white",
                                            backgroundColor: props.value ? "#ffffff" : "#4D4D4D",
                                        }}
                                    />
                                )}
                                shouldAutoFocus={true}
                                  focusStyle={{
                                    backgroundColor: "white",
                                    border: "1px solid #CFD3DB",
                                    outline: "none"
                                  }}
                                />
                            <p className='mt-3 text-center'>ยังไม่ได้รับรหัส OTP?  <u className='requestOtpAgain' onClick={handleGetOtpAgain}>ขอรหัส OTP</u></p>
                            </>
                        }

                    </div>
            </Container>
        </div>
    )
}

export default OtpPage