import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Container} from 'react-bootstrap'
import './pinPage.scss'
import OtpInput from "react-otp-input";
import loginApi from '../../../service/serviceLogin';
import Loading from '../helper/loading';

const PinConfirm = () => {
    const loginPIN = new loginApi.PIN()
    const processLogin = new loginApi.ProcessLogin()
    const userNameLogin = localStorage.getItem('userName')
    const navigate = useNavigate()
    const [otp, setOtp] = useState('');
    const [wrongPin , setWrongPin] = useState(false)
    const [loading, setLoading] = useState(false)

    
    const handleOtpChange = (inputOtp) => {
        setOtp(inputOtp);
        if (inputOtp.length === 6) {     
            confirmPin(inputOtp)
        } else {
            setWrongPin(false)
        }
    };


    const confirmPin = async (inputOtp) => {
        
        setLoading(true)
        const data = {
            "password": inputOtp
        }
        try {
            let checkLogin = await processLogin.checkProcessLogin(userNameLogin)


            const res = await loginPIN.authPIN(data)
            if (res.data.status) {
                setLoading(true)
                localStorage.setItem("authToken", res.data.token);      
                if(!checkLogin.data.pdpa){
                    navigate("/pdpa")
                } else {
                    window.location.reload()  
                }                            
            } else {
                setLoading(false)
                setWrongPin(true);
                return;
            }            
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div id="pinPage">
            <div className="loginHeader d-flex flex-row p-4">                
                    <h2 className="text-white ms-3">ยืนยันรหัส PIN</h2>
            </div>
            <Container className='d-flex flex-column align-items-center pt-5'>
                    <div className='pinTextDescription d-flex flex-column align-items-center'>
                            <div className='mb-5 d-flex flex-column align-items-center'>
                                <p>ยืนยันรหัส PIN</p>
                                <p>Confirm your number pin </p>
                                {wrongPin && <p className='textRed m-0'>รหัส PIN ไม่ถูกต้อง</p>}
                            </div>    
                        {
                            loading ?
                            <Loading />
                                :
                            <OtpInput
                            value={otp}
                            onChange={handleOtpChange}
                            numInputs={6}
                            renderSeparator={<span style={{ width: "8px" }}></span>}
                            inputType="number"
                            renderInput={(props, index) => {
                                return (
                                <input
                                    {...props}
                                    value=""
                                    style={{
                                        border: `2px solid  ${wrongPin ? "red":
                                        otp?.length === index ? "white" : "transparent"}`,
                                        borderRadius: "50%",
                                        width: "30px",
                                        height: "30px",
                                        fontSize: "12px",
                                        color: "#000",
                                        fontWeight: "400",
                                        caretColor: "transparent",
                                        backgroundColor: props.value ? "#ffffff" : "#4D4D4D",
                                    }}
                                />
                            )}}
                                caretRender={() => null}
                                isInputNum={true}
                                shouldAutoFocus={true}
                        />  
                        }                    
         
                    </div>
            </Container>
        </div>
    )
}

export default PinConfirm